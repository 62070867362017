.icon {
  display: inline-flex;
}

.smallIcon {
  width: 1.875rem;
  height: 1.875rem;
}

.svg {
  height: 100%;
  width: 100%;
}
