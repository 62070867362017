@import "../../styles/variables";

.Details {
  background-color: $white;
  padding: $vertical-space $horizontal-space;
  text-align: left;
  padding-bottom: 100px;
}

.preview { 
  background-color: $body-background-color;
  padding: 16px;
}

.previewBox {
  padding: 24px;
  background-color: $white;
  margin-bottom: 24px;
}

.previewBoxMobile {
  max-width: 320px;
}