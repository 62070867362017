@import "mixins";
@import "mq";
@import "variables";

// default styles applied to tags before any classes are applied
// editable on a per-project basis

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
}

body {
  line-height: 1.75;
  font-family: $base-font;
  -webkit-font-smoothing: antialiased;
  background-color: $body-background-color;
  color: $default-text-color;
  overflow-x: hidden;
  min-height: 100%;
  min-height: 100vh;
  letter-spacing: 0.02em;
}

h1 {
  @include headingFont($h1);
  font-size: $h1;
  line-height: $h1-lh;
  margin-bottom: 10px;

  @include mq($from: XL) {
    font-size: $h1-lg;
    line-height: $h1-lg-lh;
  }
}

// Could use a function to map these
h2 {
  @include headingFont($h2);
  font-size: 30px;
  line-height: 1.33;
  margin-bottom: 10px;

  @include mq($from: XL) {
    font-size: 38px;
    line-height: 1.26;
  }
}

h3 {
  @include headingFont($h3);
  font-size: 26px;
  line-height: 1.38;
  margin-bottom: 5px;

  @include mq($from: XL) {
    font-size: 28px;
    line-height: 1.36;
  }
}

// Not used in the designs - if using those - they need to be reviewed
h4 {
  @include headingFont($h4);
  font-size: 24px;
  line-height: 1.38;
  margin-bottom: 5px;

  @include mq($from: XL) {
    font-size: 26px;
    line-height: 1.38;
  }
}

h5 {
  @include headingFont($h5);
  font-size: 22px;
  margin-bottom: 5px;

  @include mq($from: XL) {
    font-size: 24px;
    line-height: 1.38;
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: $primary-brand;
}

p {
  margin-top: 0;
  line-height: $baseline;
  margin-bottom: $baseline;
}

a {
  color: $default-link-color;
  text-decoration: underline;

  &:hover {
    color: $hover-link-color;
    text-decoration: none;
  }
}

ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 600;
}

label:hover {
  cursor: pointer;
}

.show-vertical-rhythm {
  background-image: linear-gradient(rgba(200, 50, 50, 0.25) 1px, transparent 1px);
  background-size: 1px $baseline;
  padding-bottom: 1px;
  margin-bottom: $baseline;
}
