input,
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"] {
  height: auto;
  margin: 0;
  min-height: $input-height;
  padding: $input-padding;
  color: $secondary-brand;
  border: 1px solid $border-color;
  background: $input-background-color;
  -webkit-appearance: none;
  width: 100%;
  letter-spacing: .5px;
  font-size: 1rem;

    &:invalid {
    border-color: $red;
    color: $red;
  }

  &:active,
  &:focus {
    border-color: $primary-brand;
    border-radius: 0;
    outline: none;
  }

  // IE 10/11 padding issue
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding-bottom: 18px;
  }
}

input[type="radio"],
input[type="checkbox"] {
  -webkit-appearance: none;
}
