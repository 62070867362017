@import "variables";
@import "mixins";
@import "mq";

// Helper classes - Looking for text alignment, visibility, or display type? Use the current boilerplate framework classes for that! These are additions.
.clear {
  clear: both;
}

.clearfix {
  @include clear();
}

.strong {
  font-weight: 600;
}

.unbutton {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;

  &:hover {
    background: transparent;
  }
}

// "Full width" Elements
.fw-container {
  width: 100%;
  max-width: 2560px;
  margin: auto;
}

// To constrain content inside a fw-container. This class should not be ON your fw-container, but an element within.
.constrain {
  max-width: $max-content-width;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;

  @include mq($from: M) {
    padding: 0;
  }
}

// More specific than individual components
.branded {
  background: $primary-brand;
  color: $white;

  h1,
  h2,
  h3,
  h4,
  h5,
  a {
    color: $white;
  }

  &--alt {
    background: $secondary-brand;
  }
}