////// Set variables and spriting specific to this project.

//// Spacing Variables
$vertical-space: 20px;
$horizontal-space: 24px;
$content-width: 94%; // Page width
$max-content-width: 1180px; // Maximum width before it just ends up centered (Containers will extend by default)
$border-radius: 4px;

// Colours
$black: #111111;
$white: #ffffff;

$light-blue: #f4f8ff;
$blue: #becae3;
$red: red;

$primary-brand: #ff4700;
$secondary-brand: $black;
$lightgrey: #c4c4c4;
$darkgrey: #464646;
// e.g Link colour, bg, text colour, hover colour, active colour
$body-background-color: $light-blue;
$input-background-color: $light-blue;
$default-text-color: $black;
$secondary-text-color: $darkgrey;
$default-link-color: $primary-brand;
$hover-link-color: $darkgrey;
$border-color: $blue; // Might need to change this

:root {
  --primary-brand: $primary-brand;
  --secondary-brand: $secondary-brand;
  --white: $white;
  --black: $black;
}

// Fonts
// Common font stacks should be defined in util.scss, specific ones can be named or referenced here
$base-font: "Rubik", sans-serif;
$heading-font: "Rubik", sans-serif;

$baseline: 1.75rem;

$h1: 34px;
$h1-lh: 1.29;
$h2: 1.8rem;
$h3: 1.75rem;
$h4: 1.5rem;
$h5: 1.25rem;

$h1-lg: 48px;
$h1-lg-lh: 1.25;

//// Media Queries
// Uncomment this if your site isn't responsive. Enjoy your trip to the past!
//$mq-responsive: false;

// Responsive breakpoints, use semantic names
$mq-breakpoints: (
  XS: 320px,
  S: 480px,
  M: 720px,
  L: 980px,
  XL: 1024px,
  XXL: 1280px,
);

// Define the breakpoint from the $mq-breakpoints list that IE8 should get
$mq-static-breakpoint: XL;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: () !default;

//// Animation Variables
$transition-speed: 0.1s;
$transition-timing: ease-in-out;

// Forms
$input-height: 30px;
$input-padding: 24px 15px;
