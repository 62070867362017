@import "../../styles/variables";

.Instructions {
  padding: 32px 32px 64px;
  background-color: $white;
  text-align: left;

  ol {
    margin-top: 8px;
    margin-left: 32px;
    margin-bottom: 32px;
    list-style-type: decimal;
  }
}

.howToLink {
  cursor: pointer;
}

.advanced {
  padding-top: 64px;
}

.note {
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 32px;
  background-color: $body-background-color;

  :last-child{
    margin-bottom: 0;
  }
}