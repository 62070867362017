@import "./styles/variables";

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  height: 100px;
}

.App-header {
  padding: 30px;
  background-color: $black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
}
